'use client'
// Form
import { useForm } from 'react-hook-form'
// UI
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  PasswordInput
} from '@/_components/ui'
// Lib
import { Input } from '@/_components/ui/input'
// Next Auth
import { getSession, signIn } from 'next-auth/react'
// Sonner
import { toast } from 'sonner'
// Router
import { useRouter } from 'next/navigation'
// Next
import Link from 'next/link'
// Icons
import { ChevronRightIcon } from 'lucide-react'
// Validation

import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import { z } from 'zod'

const formSchema = z.object({
  email: z.string().email(),
  password: z.string()
})

type FormValues = z.infer<typeof formSchema>

export function LoginForm() {
  const router = useRouter()
  const [isLoading, setIsLoading] = useState(false)

  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema)
  })

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch
  } = methods

  const onSubmit = (data: FormValues) => {
    setIsLoading(true)
    return signIn('credentials', {
      redirect: false,
      email: data.email,
      password: data.password
    }).then(async (res) => {
      if (res?.error) {
        setIsLoading(false)
        toast.error(res.error)
      } else {
        const session = await getSession()
        toast.message(`👋 Welcome back ${session?.user?.name}!`)
        router.push('/dashboard')
      }
    })
  }

  return (
    <Form {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <div>
          <FormField
            control={control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input placeholder="Enter your email" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div>
          <FormField
            control={control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Password</FormLabel>
                <FormControl>
                  <PasswordInput
                    placeholder="Enter your password..."
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex items-center justify-end">
          <Button asChild variant="link" size="sm" className="p-0 text-sm">
            <Link href={`/forgot-password?email=${watch().email}`}>
              Forgot password?
            </Link>
          </Button>
        </div>
        <div className="w-full">
          <Button
            type="submit"
            loading={isSubmitting || isLoading}
            className="w-full"
          >
            Sign In
            <ChevronRightIcon className="ml-4" />
          </Button>
        </div>
      </form>
    </Form>
  )
}
